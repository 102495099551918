<template>
  <div class="hello">
    <el-calendar v-model="value" @click.native="changec" ref="cal">
      <template slot="dateCell" slot-scope="{ date, data }">
        <div :class="data.isSelected ? 'is-selected' : ''">
          <div class="title">{{ data.day.slice(5) }}</div>
          <div
            :class="genClass(data.day)"
            v-show="data.type === 'current-month'"
          >
            {{ getNum(data.day) }}
          </div>
        </div>
      </template>
    </el-calendar>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="到期列表" name="1">
        <template>
          <el-table
            :data="tableData"
            :default-sort="{ prop: 'end', order: 'ascending' }"
          >
            <el-table-column type="index" width="50" align="center" label="No.">
            </el-table-column>
            <el-table-column prop="type" label="APP" sortable>
              <template slot-scope="scope">
                <span :class="`interest ${scope.row.type}`">{{
                  scope.row.type | appName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="range"
              label="期限"
              align="center"
            ></el-table-column>
            <el-table-column prop="end" label="日期" sortable></el-table-column>
          </el-table>
        </template>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import moment from "moment";
import origin from "./list";
let nameMap = {
  dxm: "度小满",
  zgc: "中关村银行",
  fm: "富民银行",
};
export default {
  name: "HelloWorld",
  data() {
    return {
      activeNames: [],
      activeNames2: [],
      value: new Date(),
      list: [],
      curMonth: moment().format("YYYY-MM"),
      tableData: origin,
    };
  },
  computed: {
    genClass() {
      return (day) => {
        let obj = this.list.find((obj) => obj.time === day);
        if (obj) {
          return `interest ${obj.type}`;
        } else {
          return "interest";
        }
      };
    },
    getNum() {
      return (day) => {
        let obj = this.list.find((obj) => obj.time === day);
        if (obj) {
          return obj.interest;
        } else {
          return "";
        }
      };
    },
  },
  mounted() {
    this.generateList(origin);
  },
  filters: {
    appName(type) {
      return nameMap[type];
    },
  },
  methods: {
    generateList(arr) {
      let temp = [];
      arr.forEach((obj) => {
        let res = this.genDateList(obj);
        temp = temp.concat(res);
      });
      this.list = this.quChong(temp);
      this.setTitle(this.curMonth);
    },
    genDateList({ start, end, range, type, interest, dateType }) {
      let temp = [];
      let s = moment(start);
      let e = moment(end);
      let t = dateType ? dateType : "months";
      for (let i = s; i <= e; ) {
        temp.push({
          time: i.format("YYYY-MM-DD"),
          interest: interest,
          type: type,
        });
        // 如果是某个月的后几天，需要单独处理
        if (["29", "30", "31"].includes(start.split("-")[2])) {
          i = i.add(range, t);
        } else {
          i = i.add(range, t);
        }
      }
      return temp;
    },
    quChong(arr) {
      let temp = [];
      arr.forEach((obj) => {
        let target = temp.find((_obj) => _obj.time === obj.time);
        if (target) {
          target.interest += obj.interest;
        } else {
          temp.push(obj);
        }
      });
      return temp;
    },
    changec(e) {
      this.curMonth = this.$refs.cal.curMonthDatePrefix;
    },
    setTitle(m) {
      let sum = this.list.reduce((total, cur) => {
        if (m === cur.time.slice(0, 7)) {
          return total + cur.interest;
        } else {
          return total;
        }
      }, 0);
      document.title = `付息时间表（${sum}）`;
    },
  },
  watch: {
    curMonth: {
      handler: function (n, o) {
        this.setTitle(n);
      },
      immediate: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.is-selected {
  color: #2980b9;
  font-weight: bold;
}

.hello::v-deep .el-calendar-table .el-calendar-day {
  padding: 0;

  & > div {
    padding-top: 12px;
  }
}

.hello::v-deep .title {
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
}

.hello::v-deep .interest {
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
}

.hello::v-deep .interest.dxm {
  color: #e74c3c;
}

.hello::v-deep .interest.zgc {
  color: #3498db;
}

.hello::v-deep .interest.fm {
  color: #1abc9c;
}

.el-collapse {
  margin: 0 20px;
}

@media screen and (max-width: 700px) {
  .hello::v-deep .title {
    padding-left: 2px;
  }
}
.el-collapse-item::v-deep .el-collapse-item__header {
  opacity: 0;
}
</style>
